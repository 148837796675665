import React from "react";
import {Button, Container, Row, Col} from "reactstrap";
import "./WhatWeDo.scss";

class WhatWeDoPageContent extends React.Component {
	render() {
		return (
			<>
				<Container>
					<div className="main-what-we-do-content">
						<div className="expertise-heading">
							<span>It Starts With Expertise</span>
							<div className="description expertise-description">
								FSS enables you to get highly skilled IT personnel just when and where you need them across an amazingly wide range of technologies and domains to help you form and strengthen your team in order to remain flourishing and competitive in the marketplace. The FSS offering lends you unique advantages and value propositions whether you need to extend your IT capabilities or acquire specialized IT skills to develop, manage, enhance and support your mission critical applications.
							</div>
						</div>
						<div className="services-main-heading">
							<span className="who-title">OUR</span> 
							<span className="we-are-title"> SERVICES</span>
						</div>
						<Row>
							<Col className="ml-auto mr-auto" md="6">
								<div className="main-heading">
									<span className="service-heading">Application Development & Support</span>
								</div> <br />
								<div className="mobile-pics">
									<img width="100%" alt="" src={require("assets/img/compressedImages/development2.jpg")}/>
								</div>
								<div className="description description-content">
									Application development goes through a process of  software development life cycle (SDLC),it involves planning, creating, testing, and deploying an information system. Applications are also often developed to automate business processes ,Create a product to solve specific market problems, or push creativity. Today, the majority of organizations are looking for customized solutions to support their individual business needs and to match up to cutting edge technologies by developing scalable, secure and easy application
								</div> <br />
								<div className="button-one">
									<a href='/development'> 
										<Button  size="sm" outline color="info">Read More</Button>
									</a>
								</div> 
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="image-background">
									<img width="80%" alt="" src={require("assets/img/compressedImages/development2.jpg")}/>
								</div>
							</Col>
						</Row>
						<Row style={{marginTop : "10%"}}>
							<Col className="ml-auto mr-auto" md="6">
								<div className="image-background">
									<img width="80%" alt="" src={require("assets/img/compressedImages/recruitment3.jpg")}/>
								</div>
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="main-heading">
									<span className="service-heading">Staffing & Recruitment</span>
								</div> <br />
								<div className="mobile-pics">
									<img width="100%" alt="" src={require("assets/img/compressedImages/recruitment3.jpg")}/>
								</div>
								<div className="description description-content">
									Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies. Our clients can ramp up a team on/offshore very quickly; taking advantage of significant cost savings without any of the hassles. FSS offerings are governed by its sharp focus on “understanding the customer’s needs and priorities in minutest details” and providing “the right IT resources with the stipulated timelines”
								</div> <br />
								<div className="button-one">
									<a href='/staffing-and-recruiting'> 
										<Button  size="sm" outline color="info">Read More</Button>
									</a>
								</div> 
							</Col>
						</Row>
						<Row style={{marginTop : "10%"}}>
							<Col className="ml-auto mr-auto" md="6">
								<div className="main-heading">
									<span className="service-heading">BPO</span>
								</div> <br />
								<div className="mobile-pics">
									<img width="100%" alt="" src={require("assets/img/compressedImages/bpo2.jpg")}/>
								</div>
								<div className="description description-content">
									The success of any business depends largely on the satisfaction of the customer which is generally achieved through communication. This also boosts the brand's image. We have a team of experts that can effectively deliver customer support across multiple channels. By outsourcing your requirements to us you can save on labor, technology, infrastructure, and operating costs. You'll also have access to knowledgeable resources. These professionals would deliver projects on time and guarantee a high level of quality at each project stage in the Federal Soft System.
								</div> <br />
								
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="image-background">
									<img width="80%" alt="" src={require("assets/img/compressedImages/bpo2.jpg")}/>
								</div>
							</Col>
						</Row>
						<Row style={{marginTop : "10%"}}>
							<Col className="ml-auto mr-auto" md="6">
								<div className="image-background">
									<img width="80%" alt="" src={require("assets/img/compressedImages/payroll-min.jpg")}/>
								</div>
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="main-heading">
									<span className="service-heading">Payroll</span>
								</div> <br />
								<div className="mobile-pics">
									<img width="100%" alt="" src={require("assets/img/compressedImages/payroll-min.jpg")}/>
								</div>
								<div className="description description-content">
									Payroll is the process of paying a company's employees, which can include the tracking of hours worked, the calculation of employee's pay, and the distribution of payments via direct deposit directly to their account or by check. A company's payroll is the list of employees of that company that are entitled to receive pay and the amounts that each should receive. They include employee salaries, employer payments for health insurance or similar benefits, payroll taxes paid by the employer, bonuses, commissions and similar expenses.
								</div> <br />
							</Col>
						</Row> 
					</div>
				</Container> 
				<div className="model-main-heading">
						<span className="engagement-title">ENGAGEMENT</span>
						<span className="model-title"> MODELS</span>
						<Row>
							<Col className="ml-auto mr-auto" md="6">
								<div className="model-heading">
									TURN-KEY
								</div>
								<p>We are your integral application partner in a turn-key engagement. We work with you to strategize, design and develop a cohesive solution for your business problem. Then, we design, build, test and refine the solution until it’s ready for delivery and deployment. Clients can be as involved or hands-off as they want in the process.</p>
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="model-heading">
									EXTENSION
								</div>
								<p>Our customers typically have an internal application development department but need assistance with a particular problem, such as the capacity for burst growth, project management or UX design. In a team extension engagement  we  supply the customer on a contract basis with appropriate representatives of the Federal Soft Systems squad. This means our clients get just the support they need for as long as they need it.</p>
							</Col>
						</Row>
					</div>
			</>
		)
	}
}

export default WhatWeDoPageContent;


/***************************************************************************************************************/







/* <div className="heading-content">
	WE STRIVE TO DELIVER YOU THE REQUIRED APPLICATION TO HELP SOLVE YOUR BUSINESS PROBLEMS
</div> <br /> */

/* <div className="button-one">
	<a href='/bpo'> 
		<Button  size="sm" outline color="info">Read More</Button>
	</a> 
</div>

<div className="button-one">
	<a href='/payroll'> 
		<Button  size="sm" outline color="info">Read More</Button>
	</a> 
</div> */

/* 
<div className="services-main-heading main-model" style={{backgroundColor: "#ffffff"}}>
	<span className="who-title">ENGAGEMENT</span> 
	<span className="we-are-title"> MODELS</span>
	<Row style={{marginTop : "5%"}}>
		<Col className="ml-auto mr-auto" md="6">
			<div className="main-heading">
				<span className="service-heading">Turn Key</span>
			</div>
		</Col>
		<Col className="ml-auto mr-auto" md="6">
		</Col>
	</Row>
</div>  */