import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";

class DigitalTransformation extends React.Component {
	render() {
		return (
			<>
				<IndexNavbar />
				<div 
					className="page-header section-dark"
					style={{
						backgroundImage:
						"url(" + require("assets/img/customImages/digitalTransformation-min.jpg") + ")"
					}}
				>
				</div>
				<div className="all-pages-content">
					<p>
						Digital transformation is the process of using digital technologies to create new business processes, culture, and customer experiences or to modify existing ones to meet changing business and market needs. Digital transformation is a reimagination of business in the digital age. The Digital Transformation portfolio enables industrial companies of all sizes to implement current and future technologies for the automation and digitalization. We define digital transformation as the integration of digital technology into all areas of a business resulting in fundamental changes to how businesses operate and how they deliver value to customers.
					</p>
				</div>
				<FooterTwo />
			</>
		)
	}
}

export default DigitalTransformation;