import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import CustomFooter from "components/CustomFooter";
import BpoPageContent from "./BpoPageContent";

class BpoMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                <div className="page-header section-dark" style={{height: "100vh"}}>
                    <video autoPlay loop muted>
                        <source src={require("assets/img/customImages/bg5.mp4")} type='video/mp4' />
                    </video>
                </div>
                <BpoPageContent />
                <CustomFooter />
            </>
        )
    }
}

export default BpoMainContent;