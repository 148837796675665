import React, {useState} from "react";
import {Container, ButtonGroup, Button} from "reactstrap";
import {Link} from "react-router-dom";
import "../careers.scss";

function CareersPresentational(props) {
    const [rSelected, setRSelected] = useState('united states');
    const handlecountry = (selectedCountry) => {
        setRSelected(selectedCountry);
        props.componentDidMount(selectedCountry);
    }
    const {JobsList, listLength} = props;
    console.log('JobsList in presentational', JobsList);
    return (
        <>
            <div className="careers-content">
                <Container>
                    We are always in search of difference makers who bring provocative ideas, diverse perspectives and unwavering passion for the ever-changing technology landscape. We believe the whole is greater than the sum of its parts, as a result we encourage teamwork and foster camaraderie.
                </Container>
            </div>
            <Container>
                <ButtonGroup className="custom-buttons">
                    <Button color="info" className="button1" onClick={() => handlecountry("united states")} active={rSelected === 'united states'}>USA</Button>
                    <Button color="info" className="button2" onClick={() => handlecountry("india")} active={rSelected === 'india'}>INDIA</Button>
                </ButtonGroup>   
                {listLength > 0 &&                   
                    <div className="custom-table">
                        <table className="cs-table">
                            <thead>
                                <tr>
                                    <th>JOB CODE</th>
                                    <th>POSITION</th>
                                    <th>POSTED ON</th>
                                    <th>TYPE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {JobsList && JobsList.map((el,index) => 
                                    <tr key={index}>
                                        <td>{el.job_code}</td>
                                        <td>{el.job_title}</td>
                                        <td>{el.job_posted_date_time}</td>
                                        <td>{el.job_type_name}</td>
                                        <td>
                                            <Link to={`/job-application/${el.job_id}`} target="_blank">
                                                <i className="fa fa-arrow-right"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
                <div>
                    {listLength === 0 && 
                        <h4 style={{textAlign : "center", paddingBottom : "35px", fontWeight : "400", color : "#ca052e"}}>No Current Openings</h4>
                    }
                </div>
            </Container>
        </>
    )
}

export default CareersPresentational;


/* <div className="open-positions-main">
    <span className="open-title">OPEN</span> 
    <span className="position-title"> POSITIONS</span>
</div> */

/* const IndiaTableData = [
    {jobId: "1", position : "UI Developer", postedon : "22/01/2020", type : "full-time"},
    {jobId: "2", position : "Java Developer", postedon : "22/01/2020", type : "full-time"},
    {jobId: "3", position : "UI / UX developer", postedon : "23/01/2020", type : "full-time"},
    {jobId: "4", position : "Php Developer", postedon : "23/01/2020", type : "full-time"},
    {jobId: "5", position : "Senior UI Developer", postedon : "24/01/2020", type : "full-time"},
]

const UsaTableData = [
    {jobId: "101", position : "UI Developer", postedon : "22/01/2020", type : "full-time"},
    {jobId: "102", position : "Java Developer", postedon : "22/01/2020", type : "full-time"},
    {jobId: "103", position : "UI / UX developer", postedon : "23/01/2020", type : "full-time"},
] */


/* {rSelected == 1 && 
    <div className="custom-table">
        <table className="cs-table">
            <thead>
                <tr>
                    <th>JOB ID</th>
                    <th>POSITION</th>
                    <th>POSTED ON</th>
                    <th>TYPE</th>
                </tr>
            </thead>
            <tbody>
                {UsaTableData.map (el => 
                    <tr>
                        <td>{el.jobId}</td>
                        <td>{el.position}</td>
                        <td>{el.postedon}</td>
                        <td>{el.type}</td>
                        <td>
                            <a href="/job-application" target="_blank">
                                <i class="fa fa-arrow-right"></i>
                            </a>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}
{rSelected == 2 && 
    <div className="custom-table">
        <table className="cs-table">
            <thead>
                <tr>
                    <th>JOB ID</th>
                    <th>POSITION</th>
                    <th>POSTED ON</th>
                    <th>TYPE</th>
                </tr>
            </thead>
            <tbody>
                {IndiaTableData.map (el => 
                    <tr>
                        <td>{el.jobId}</td>
                        <td>{el.position}</td>
                        <td>{el.postedon}</td>
                        <td>{el.type}</td>
                        <td>
                            <a href="/job-application" target="_blank">
                                <i class="fa fa-arrow-right"></i>
                            </a>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
} */