import React, { Fragment } from "react";
import { Timeline, Event } from "react-timeline-scribble";
import {Container} from "reactstrap";

const WhoWeAreTimeLine = () => (
	<Container>
		<Fragment>
			<Timeline>
				<Event interval={"2015"} title={"IT Consulting Services"} subtitle={"USA"}>
					Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies. Our clients can ramp up a team on/offshore very quickly; taking advantage of significant cost savings without any of the hassles. FSS offerings are governed by its sharp focus on “understanding the customer’s needs and priorities in minutest details” and providing “the right IT resources with the stipulated timelines”
				</Event>
				<Event interval={"2016"} title={"Operations Offshore"} subtitle={"INDIA (offshore)"}>
					Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies. Our clients can ramp up a team on/offshore very quickly; taking advantage of significant cost savings without any of the hassles. FSS offerings are governed by its sharp focus on “understanding the customer’s needs and priorities in minutest details” and providing “the right IT resources with the stipulated timelines”
				</Event>
				<Event interval={"2017"} title={"Recruitment"} subtitle={"USA & INDIA"}>
					Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies.
				</Event>
				<Event interval={"2018"} title={"The Start of Software Development Wing"} subtitle={"INDIA"}>
					Apart from consulting services, we at Federal Soft Systems have thought of have a crew of developers who can work on projects from our clients directly from our office location. Thus forms the new software development wing in the company dealing with successful projects. To make it cost efficient we have started this program in India.
				</Event>
				<Event interval={"2019"} title={"Entered into BPO Services"} subtitle={"INDIA (offshore)"}>
					In search for business we were searching for any opportunity that we can explore and set new standards in. In this process our Business development managers have got hold of a BPO project which we are working out efficiently.
				</Event>
				<Event interval={"2020"} title={"Seeking to do more"} subtitle={"We believe we will do more in this year"}>
					We are a very enthusiastic organization looking forward to embracing technical advancements of any type.
				</Event>
			</Timeline>
		</Fragment>
	</Container>
);


export default WhoWeAreTimeLine; 