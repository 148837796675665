import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
import {Container} from "reactstrap";
import "./Home.scss";

function CustomSlider () {
    return (
        <div>
            <Container>
            <InfiniteCarousel
                breakpoints={[
                    {
                        breakpoint: 500,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        },
                    },
                ]}
                    dots={false}
                    showSides={true}
                    sidesOpacity={.5}
                    sideSize={.1}
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={true}
                    autoCycle={true}
                    cycleInterval={2000}
            >
                <div className="image-one"></div>
                <div className="image-two"></div>
                <div className="image-four"></div>
                <div className="image-twenty-one"></div>
                <div className="image-five"></div>
                <div className="image-seventeen"></div>
                <div className="image-twenty"></div>
                <div className="image-six"></div>
                <div className="image-seven"></div>
                <div className="image-eight"></div>
                <div className="image-nine"></div>
                <div className="image-ten"></div>
                <div className="image-eleven"></div>
                <div className="image-twelve"></div>
                <div className="image-fourteen"></div>
                <div className="image-fifteen"></div>
                <div className="image-sixteen"></div>
                <div className="image-eighteen"></div>
                <div className="image-nineteen"></div>
            </InfiniteCarousel>
            </Container>
        </div>
    )
}

export default CustomSlider;