import React from "react";
import {Button, Container, Row, Col} from "reactstrap";
import "./Home.scss";
import "animate.css/animate.min.css";
//import ScrollAnimation from 'react-animate-on-scroll';
import CustomSlider from "./Slider";

class HomePageContent extends React.Component {
    render() {
        return (
            <>
                <div className="main-home-page-content">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="main-heading">
                                    <span className="who-title">WHO</span> 
                                    <span className="we-are-title"> WE ARE</span>
                                </div> <br />
                                <div className="who-we-are-heading-content">
                                    WE STRIVE TO DELIVER YOU THE REQUIRED APPLICATION TO HELP SOLVE YOUR BUSINESS PROBLEMS
                                </div> <br />
                                <div className="mobile-pics">
                                    <img  width="100%" alt="" src={require("assets/img/compressedImages/whoHome.jpg")}/>
                                </div>
                                <div className="description description-content">
                                    We are honored to become a valued member of our clients. We help them manage the environment and become trusted partners in their decisions regarding their development strategies. And, no other company will compare with our ability to turn consumer approaches into real returns on investment. We are the leaders in business.
                                </div> <br />
                                <div className="button-one">
                                    <a href='/who-we-are'> 
                                        <Button  size="sm" outline color="info">Learn More</Button>
                                    </a>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="image-background">
                                    <img alt="" src={require("assets/img/compressedImages/whoHome.jpg")}/>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop : "10%"}}>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="image-background">
                                    <img alt="" src={require("assets/img/compressedImages/whatPage.jpg")}/>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="main-heading">
                                    <span className="who-title">WHAT</span> 
                                    <span className="we-are-title"> WE DO</span>
                                </div> <br />
                                <div className="who-we-are-heading-content">
                                    WE BUILD SOFTWARE, CHECK AND CUSTOMIZE FRAMEWORKS FOR A BETTER BUSINESS APPROACH
                                </div> <br />
                                <div className="mobile-pics">
                                    <img width="100%" alt="" src={require("assets/img/compressedImages/whatPage.jpg")}/>
                                </div>
                                <div className="description description-content">
                                    After we have a thorough discussion about your company, your market, your strengths and your challenges, we will work with you to develop a strategy. The right answer could be a simple program, or an advanced development system with many complex components operating together in harmony.
                                </div> <br />
                                <div className="button-two">
                                    <a href='/what-we-do'> 
                                        <Button size="sm" outline color="info">Learn More</Button>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container> 
                    <div className="main-box">
                        <div className="box1">
                            <div className="box1-content">
                                <h5 className="latest-work">Latest Work</h5>
                                <div className="first-left-content">
                                    <h4>File Petitions</h4>
                                    <p>
                                        File Petitions is a web-based program that has been established to minimize the efforts of law firms, lawyers, employers, beneficiaries and their dependents to file petitions with the U.S. Citizenship and Immigration Services (USCIS). To reduce the hectic paper work performed when filing a petition, we have introduced a lot of elements such as form filling that can be explored through this framework after entering the information in a document, application status, timelines, notifications, RFE progress and several other features.
                                        
                                    </p>
                                </div>
                                <div className="first-right-content"></div>
                                <div className="second-left-content"></div>
                                <div className="second-right-content">
                                    <h4>Resume Crab</h4>
                                    <p>
                                        Resume Crab is an application which is created with recruiters in mind. We recognize how much effort a recruiter or salesperson requires to position the applicant against the criteria or positions that every organization has listed. We also designed this application in such a manner as to alert the recipient of the application about every work listing that is held in the market such that he / she can respond to the listing without loss of time, thus accessing the thousands of resumes that are accessible in the application itself.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Container>
                        <div className="our-main-presence">
                            <span className="our-title">OUR</span> 
                            <span className="presence-title"> PRESENCE</span>
                            <div className="description our-presence-content">
                                We are market-wide in the fields of Software Development, Information Technology Consulting, Recruitment, Outsourcing of Business Processes, Communications & Internet, Engineering, Hospitality and Transportation Services and many others.<a href="/our-presence" style={{fontWeight: "500"}}> Read More</a>
                            </div>
                        </div>
                    </Container>
                    <div className="slider-background">
                        <div className="heading-pad">
                            <span className="our-title">OUR</span> 
                            <span className="clients-title"> CLIENTS</span>
                        </div>
                        <div className="slider-pad">
                            <CustomSlider />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default HomePageContent;

/* <a href="/our-work" style={{fontWeight: "500"}}> Read More</a>
<a href="/our-work" style={{fontWeight: "500"}}> Read More</a> */

/* <div className="text-center">
    <Container>
        <Row>
            <Col className="ml-auto mr-auto" md="12">
                <h2>
                    <span className="who-title">WHO</span> 
                    <span className="we-are-title"><b> WE ARE</b></span>
                </h2> <br />
                <div className="who-we-are-heading-content">WE STRIVE TO DELIVER YOU THE REQUIRED APPLICATION TO HELP SOLVE YOUR BUSINESS PROBLEMS</div> <br />
                <h5 className="description description-content">
                    We are honored to become a valued member of our clients. We help them manage the environment and become trusted partners in their decisions regarding their development strategies. And, no other company will compare with our ability to turn consumer approaches into real returns on investment. We are the leaders in business.
                </h5><br />
                <Button size="sm" color="info" href="#pablo" onClick={e => e.preventDefault()}>See Details</Button>
            </Col>
        </Row><br /><br />
    </Container>
</div> */







/* <div className="our-main-presence">
                        <span className="our-title">OUR</span> 
                        <span className="presence-title"> PRESENCE</span>
                        <Container>
                            <Row className="cards">
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='zoomIn'>
                                        <div className="first-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='pulse'>
                                        <div className="second-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='zoomIn'>
                                        <div className="third-card"></div>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                            <Row className="cards">
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='pulse'>
                                        <div className="fourth-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='zoomIn'>
                                        <div className="fifth-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='pulse'>
                                        <div className="sixth-card"></div>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                            <Row className="cards">
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='zoomIn'>
                                        <div className="seventh-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='pulse'>
                                        <div className="eight-card"></div>
                                    </ScrollAnimation>
                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <ScrollAnimation animateIn='zoomIn'>
                                        <div className="ninth-card"></div>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Container>
                    </div> */