import React from "react";
import JobViewPresentational from "./JobViewPresentational";
import axios from "axios";

class JobViewContainer extends React.Component {

    state = {
        ApplyJobsList : "",
    }

    componentDidMount = () => {

        const headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json', 
        }

        axios.post('https://api-live.resumecrab.com/rest/prelogin-job/details-get', {
            company_id : "1",
            job_id : this.props.match.params.job_id,
            api_key : "3234c71c-5fad-4857-84bf-6d32def3d650",
            ip_address : "49.206.214.42",
        }, {headers : headers})
        .then(response => {
            console.log('response in job view service', response.data.data);
            this.setState({ApplyJobsList : response.data.data});
            return response;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
    }

    render() {
        console.log('jopb view props', this.props);
        return (
            <>
                <JobViewPresentational ApplyJobsList={this.state.ApplyJobsList}/>
            </>
        )
    }
}

export default JobViewContainer;