import React from "react";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Development.scss";

function TimelineContent () {
  return (
    <>
        <div className="for-large-device">
            <div className="App" style={{ background: "#F7F2F0", fontFamily: "Trebuchet Ms" }}>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/strategySmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0",  
                            boxShadow : "0 0 0 3px #fff",
                            textAlign: "center",
                            paddingTop: "11px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}>STRATEGY</h6> 
                        After we have a thorough discussion about your company, your market, your strengths and your challenges, we will work with you to develop a strategy. The right answer could be a simple program, or an advanced development system with many complex components operating together in harmony.
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/ui-uxSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "12px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}>UI / UX DESIGN</h6>
                        Once we decide the issue we are trying to solve, the next step is to guarantee that someone a) wants to use the approach and b) recommends it to his coworkers. We have therefore put together a team of design experts to ensure that your ideas are creative, elegant and interactive.
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/architectureSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0",  
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "12px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> ARCHITECTURE DESIGN </h6>
                        Once we have a clear understanding about how consumers connect with your approach, we then design all of the application's technical requirements. We collaborate with each level of your team to ensure that any potential use-case is addressed when designing the application's technological architecture.
                    </VerticalTimelineElement>

                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/developmentSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "12px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> DEVELOPMENT </h6>
                        This is exactly as it sounds. Once we have selected a plan and designed the user experience and technical architecture of the system, our team of professional developers will only have to develop this application.
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/qaSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "12px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> QA AND TESTING </h6>
                        Exhaustive testing is the secret to getting the roll out smooth. The harder you want to push the solution in testing, the more often you can find some glitches or errors before the go-live.We don't wait until development is finished to check each function only to figure out that there is a issue in each move.
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img  alt="" src={require("assets/img/customImages/supportSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "12px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> SUPPORT </h6>
                        An application is not a limited piece of software that we drop off after we have completed designing and reviewing it for our clients. We provide deployment assistance to ensure that the products of our customers get the buy-in and recognition they deserve and continuing support to help our clients continually improve their current products.
                    </VerticalTimelineElement>

                </VerticalTimeline>
            </div>
        </div>
        <div className="for-small-device">
            <div className="App" style={{ background: "#F7F2F0", fontFamily: "Trebuchet Ms" }}>
                <VerticalTimeline>
            
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/strategySmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0",  
                            boxShadow : "0 0 0 3px #fff",
                            textAlign: "center",
                            paddingTop: "2px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}>STRATEGY</h6> 
                        After we have a thorough discussion about your company, your market, your strengths and your challenges, we will work with you to develop a strategy. The right answer could be a simple program, or an advanced development system with many complex components operating together in harmony.
                </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/ui-uxSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "5px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}>UI / UX DESIGN</h6> 
                        Once we know what problem we're trying to solve, the next step is to ensure anyone 
                        a) to keep using your solution, and
                        b) will suggest it to their colleagues. As such, we have assembled a team of design gurus,
                        purpose built to ensure your solutions are innovative, intuitive and engaging
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/architectureSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0",  
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "4px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> ARCHITECTURE DESIGN </h6> 
                        Once we know what problem we're trying to solve, the next step is to ensure anyone 
                        a) to keep using your solution, and
                        b) will suggest it to their colleagues. As such, we have assembled a team of design gurus,
                        purpose built to ensure your solutions are innovative, intuitive and engaging
                    </VerticalTimelineElement>

                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/developmentSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "3px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> DEVELOPMENT </h6>
                        This is exactly what it sounds like. After we've decided on a strategy and designed 
                        the solution's user interface and technical architecture, we have to actually develop 
                        the mobile solution.user interface and technical architecture, we have to actually develop the mobile solution.
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img alt="" src={require("assets/img/customImages/qaSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "2px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> QA AND TESTING </h6>
                        Exhaustive testing is the key to a smooth roll out. The harder you push your solution
                        in testing, the more likely you'll catch any glitches or bugs before gametime. 
                        We don't wait until the end of development to test each feature only to find out 
                        there's a problem with each step.
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#A7C6DA', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '10px solid  rgb(167, 198, 218)' }}
                        icon = {<img  alt="" src={require("assets/img/customImages/supportSmall.png")}/>}
                        iconStyle={{ 
                            background: "#F7F2F0", 
                            boxShadow : "0 0 0 3px #fff",
                            textAlign : "center",
                            paddingTop : "2px"
                        }}
                    >
                        <h6 style={{color : "#5D576B", fontSize : "15px"}}> SUPPORT </h6>
                        A mobile solution is not a finite piece of software that we drop off for our 
                        clients once we've finished developing and testing it. We offer deployment assistance
                        to ensure our clients' solutions get the buy-in and adoption they deserve with ongoing 
                        support to help our clients continually improve their existing solutions.
                    </VerticalTimelineElement>

                </VerticalTimeline>
            </div>
        </div>
    </>
  );
}

export default TimelineContent;
