import React from "react";
import WhoWeAreTimeLine from "./WhoweareTimeline";
import "./Whoweare.scss";
import { Container} from "reactstrap";

class WhoWeArePageContent extends React.Component {
    render () {
        return (
            <>
                <div className="main-timeline">
                    <WhoWeAreTimeLine />
                </div>
                <div className="our-locations">
                    <span className="our">OUR</span> 
                    <span className="locations"> LOCATIONS</span>
                    <Container>
                        <div className="our-locations-images">
                            <a href="https://goo.gl/maps/xjooeavKtbF2zA628" target="_blank" rel="noopener noreferrer">
                                <div className="first-location">
                                    <div className="first-text">USA</div>
                                </div>
                            </a>
                            <a href="https://goo.gl/maps/6mfmtdKZrt6dzAEk6" target="_blank" rel="noopener noreferrer">
                                <div className="second-location">
                                    <div className="second-text">INDIA</div>
                                </div>
                            </a>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default WhoWeArePageContent; 