import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import ContactPageContent from "./ContactPageContent";

class ContactMainContent extends React.Component {
    render() {
        return (
            <>
                <IndexNavbar />
                    <div 
                        className="page-header section-dark"
                        style={{
                            backgroundImage:
                            "url(" + require("assets/img/compressedImages/contact2.jpg") + ")"
                        }}
                    >
                    </div>
                <ContactPageContent />
                <FooterTwo />
            </>
        )
    }
}

export default ContactMainContent;