import React from "react";
import JobApplicationPresentational from "./JobApplicationPresentational";
import axios from "axios";

class JobApplicationContainer extends React.Component {

    state = {
        statusMsg : "",
        statusDescriptionMsg : "",
    }

    jobApply = (state) => {

        const headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json', 
        }

        axios.post('https://api-live.resumecrab.com/rest/pre-login/candidate-applied/jobs-add', {
            sm_firstname : state.name || "",
            sm_lastname : state.lastName || "",
            sm_email : state.email || "",
            sm_mobile : state.phoneNumber || "",
            visa_status : state.visaStatus || "",
            visa_sponsorship_requirement_status : state.visaSponsorship || "",
            original_filename : state.orginalFileName || "",
            file_content : state.fileContent || "",
            company_id : "1",
            job_id : this.props.ApplyJobsList.job_id || "",
            api_key : "3234c71c-5fad-4857-84bf-6d32def3d650",
            ip_address : "49.206.214.42",
        }, {headers : headers})
        .then(response => {
            console.log('job application response in service', response);
            this.setState({statusMsg : response.data.status});
            this.setState({statusDescriptionMsg : response.data.status_description});
            return response;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
    }


    render() {
        const {ApplyJobsList} = this.props;
        console.log('ApplyJobsList in container of application form', ApplyJobsList);
        return (
            <>
                <JobApplicationPresentational jobApply = {this.jobApply} statusMsg={this.state.statusMsg} statusDescriptionMsg={this.state.statusDescriptionMsg}/>
            </>
        )
    }
}

export default JobApplicationContainer;