import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import "../Development.scss";

class ApplicationDevelopment extends React.Component {
	render() {
		return (
			<>
				<IndexNavbar />
				<div 
					className="page-header section-dark"
					style={{
						backgroundImage:
						"url(" + require("assets/img/customImages/applicationDevelopment-min.jpg") + ")"
					}}
				>
				</div>
				<div className="all-pages-content">
					<p>
						Application development goes through a process of  software development life cycle (SDLC),it involves planning, creating, testing, and deploying an information system. Applications are also often developed to automate business processes ,Create a product to solve specific market problems, or push creativity. Today, the majority of organizations are looking for customized solutions to support their individual business needs and to match up to cutting edge technologies by developing scalable, secure and easy application
					</p><br/>
					<p>
						It is key for many software development projects to get the product quickly on the market, ahead of the competition. This is no easy task,the skilled domestic software developers come with a high price tag, and high demand for their talents. Software  Development  outsourcing  is a smart strategy for many businesses; it allows businesses to reduce development and production time without draining budgets.
					</p>
				</div>
				<FooterTwo />
			</>
		)
	}
}

export default ApplicationDevelopment;