import React from "react";

class PayrollPageContent extends React.Component {
    render () {
        return (
            <>
                <p>PAYROLL PAGE</p>
            </>
        )
    }
}

export default PayrollPageContent;