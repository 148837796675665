import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";

class Security extends React.Component {
	render() {
		return (
			<>
				<IndexNavbar />
				<div 
					className="page-header section-dark security-bg"
					style={{
						backgroundImage:
						"url(" + require("assets/img/customImages/securityAndCompliance-min.jpg") + ")"
					}}
				>
				</div>
				<div className="all-pages-content">
					<p>
						In software, we build, attach and check security features to avoid vulnerabilities to threats such as unauthorized access and alteration. To secure user information from exposure to third party entities we use compliance methodologies such as PCI, HIPAA, GDPR. Federal Soft Systems uses a cyber technology and compliance risk control strategy including network risk evaluation, vulnerability detection, intrusion testing, open source identification & threat monitoring and security incident response, breach analysis, remediation and recovery. Our team will help you develop a compliance strategy and meet your compliance requirements while improving your overall security.
					</p>
				</div>
				<FooterTwo />
			</>
		)
	}
}

export default Security;