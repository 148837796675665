import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import CareersContainer from "./CareersPageContent";

class CareersMainContent extends React.Component {
    render() {
        return (
            <>
                <IndexNavbar />
                <div 
                    className="page-header section-dark"
                    style={{
                        backgroundImage:
                        "url(" + require("assets/img/compressedImages/careers.jpg") + ")"
                    }}
                >
                </div>
                <CareersContainer />
                <FooterTwo />
            </>
        )
    }
}

export default CareersMainContent;