import React from "react";
import {Container, Row, Col} from "reactstrap";
import TimelineContent from "./DevelopmentTimeline";
import "./Development.scss";

class DevelopmentPageContent extends React.Component {
    render () {
        return (
            <>
                <div className="development-main">

                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="card">
                                    <img width="80%" alt="" src={require("assets/img/compressedImages/applicationDevelopment-min.jpg")}/>
                                    <div className="info">
                                        <h2>Application Development</h2>
                                        <a href="/application-development">
                                            <button>Read More</button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="card">
                                    <img width="80%" alt="" src={require("assets/img/customImages/digitalTransformation-min.jpg")}/>
                                    <div className="info">
                                        <h2>Digital Transformation</h2>
                                        <a href="/digital-transformation">
                                            <button>Read More</button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="second-card-top">
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="card">
                                    <img width="80%" alt="" src={require("assets/img/compressedImages/securityAndCompliance-min.jpg")}/>
                                    <div className="info">
                                        <h2>Security & Compliance</h2>
                                        <a href="/security-and-compliance">
                                            <button>Read More</button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="card">
                                    <img width="80%" alt="" src={require("assets/img/compressedImages/cloudComputing-min.jpg")}/>
                                    <div className="info">
                                        <h2>Cloud Computing</h2>
                                        <a href="/cloud-computing">
                                            <button>Read More</button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div>
                        <div className="timeline-heading"> 
                            <span className="our">OUR</span> 
                            <span className="methodology"> METHODOLOGY</span>
                        </div>
                        <TimelineContent />
                    </div>
                </div>
            </>
        )
    }
}

export default DevelopmentPageContent;



/* <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="dev-first-card"></div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="dev-second-card"></div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10%"}}>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="dev-third-card"></div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <div className="dev-fourth-card"></div>
                            </Col>
                        </Row> */