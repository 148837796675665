import React from "react";
import { Row, Col, Container } from "reactstrap";
import ContactFormContainer from "./ContactForm";
import "./Contact.scss";

class ContactPageContent extends React.Component {
    render() {
        return (
            <>
                <div className="contact-main">
                    <div className="heading-content">
                        <span style={{ color: "#00256f" }}><b>Have Questions ? Let's</b></span>
                        <span style={{ color: "#ca052e", fontWeight: "501" }}> TALK</span>
                    </div>
                    <div className="container">
                        <Row>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <ContactFormContainer />
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <div className="contact-strategy">
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="image-align">
                                                <img alt="" src={require("assets/img/customImages/afterSubmission.png")} />
                                            </div>
                                            <div className="content-heading">AFTER SUBMITTING A REQUEST</div>
                                            <div className="content-sub-heading">Reply in 24 hours</div>
                                            <div className="content-para">Within 24 hours of receiving your completed form we will contact you via phone and/or email to find a convenient time to learn more about how we can help.</div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="image-align">
                                                <img alt="" src={require("assets/img/customImages/project.png")} />
                                            </div>
                                            <div className="content-heading">DEFINING PROJECT SCOPE</div>
                                            <div className="content-sub-heading">Business Need</div>
                                            <div className="content-para">We will work with you on our first call to identify your business requirements specifically and mutually decide if we are suitable for each other.</div>
                                        </Col>
                                    </Row> <br /> <br />
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="image-align">
                                                <img alt="" src={require("assets/img/customImages/approval.png")} />
                                            </div>
                                            <div className="content-heading">AFTER APPROVAL</div>
                                            <div className="content-sub-heading">Strategy Development</div>
                                            <div className="content-para">We will then involve you more, learn more about your business, industry, competitors and challenges to build a plan tailored to your business objectives.</div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="image-align">
                                                <img alt="" src={require("assets/img/customImages/developmentTeam.png")} />
                                            </div>
                                            <div className="content-heading">DEVELOPMENT TEAM</div>
                                            <div className="content-sub-heading">Application Development</div>
                                            <div className="content-para">The engineering department of Federal Soft Systems will create a strategy that makes economic sense for your company and will introduce it to you in a structured area of operation.</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="locations-main">
                    <div className="container">
                        <Row className="locations-main-pad">
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <div className="location-main-heading">
                                    Bentonville, USA <br />
                                    3101 SW I St, Suite# 29 & 31, AR 72712. <br />
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.9316079172004!2d-94.22476217357078!3d36.34056383202956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c91017e2c2eb49%3A0x74e8750c6ec25e46!2sFederal%20Soft%20Systems%20Inc.!5e0!3m2!1sen!2sin!4v1604553538809!5m2!1sen!2sin" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0" />
                            </Col>
                        </Row>
                        <Row className="locations-main-pad">
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3081742118206!2d78.39337941435463!3d17.49279640436194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917af6f91fb7%3A0xfbe8e742822309a2!2sFSS!5e0!3m2!1sen!2sin!4v1604554741339!5m2!1sen!2sin" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <div className="location-main-heading">
                                    Hyderabad, INDIA <br />
                                    HIG 119/II, Phase 1 & 2 <br />
                                    K P H B, Hyderabad, Telangana 500072.
                                </div>
                            </Col>
                        </Row>
                        <Row className="locations-main-pad">
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <div className="location-main-heading">
                                    Chennai, INDIA <br />
                                    W-126, 3rd Floor, 3rd Ave, <br />
                                    Anna Nagar, Chennai, Tamil Nadu 600040.
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.171923369952!2d80.21552131430543!3d13.08828821584953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526424513aab9d%3A0x924a769fbc797c5b!2sSenate%20Space!5e0!3m2!1sen!2sin!4v1604554920088!5m2!1sen!2sin" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="locations-ipad-mobile">
                    <div className="container">
                        <Row className="locations-main-pad">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="location-main-heading">
                                    Bentonville, USA <br />
                                    3101 SW I St, Suite# 29 & 31, AR 72712.
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} style={{ paddingTop: "2%" }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.9316079172004!2d-94.22476217357078!3d36.34056383202956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c91017e2c2eb49%3A0x74e8750c6ec25e46!2sFederal%20Soft%20Systems%20Inc.!5e0!3m2!1sen!2sin!4v1604553538809!5m2!1sen!2sin" width="100%" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0" />
                            </Col>
                        </Row> <br /> <br />
                        <Row className="locations-main-pad">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="location-main-heading">
                                    Hyderabad, INDIA <br />
                                    HIG 119/II, Sree Sadan, Phase 1 & 2 <br />
                                    K P H B, Hyderabad, Telangana 500072.
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} style={{ paddingTop: "2%" }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3081742118206!2d78.39337941435463!3d17.49279640436194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917af6f91fb7%3A0xfbe8e742822309a2!2sFSS!5e0!3m2!1sen!2sin!4v1604554741339!5m2!1sen!2sin" width="100%" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </Col>
                        </Row> <br /> <br />
                        <Row className="locations-main-pad">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="location-main-heading">
                                    Chennai, INDIA <br />
                                    Senate Space, W-126, 3rd Floor, 3rd Ave, <br />
                                    Anna Nagar, Chennai, Tamil Nadu 600040.
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} style={{ paddingTop: "2%" }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.171923369952!2d80.21552131430543!3d13.08828821584953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526424513aab9d%3A0x924a769fbc797c5b!2sSenate%20Space!5e0!3m2!1sen!2sin!4v1604554920088!5m2!1sen!2sin" width="100%" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default ContactPageContent;


{/* <div className="our-locations">
    <span className="our">OUR</span>
    <span className="locations"> LOCATIONS</span>
    <Container>
        <div className="our-locations-images">
            <a href="https://goo.gl/maps/xjooeavKtbF2zA628" target="_blank" rel="noopener noreferrer">
                <div className="first-location">
                    <div className="first-text">USA</div>
                </div>
            </a>
            <a href="https://goo.gl/maps/6mfmtdKZrt6dzAEk6" target="_blank" rel="noopener noreferrer">
                <div className="second-location">
                    <div className="second-text">INDIA</div>
                </div>
            </a>
        </div>
    </Container>
</div> */}



/* <form className="contact-form" action="mailto:someone@example.com" method="post" enctype="text/plain">
    <Row>
        <Col md="6">
            <label>Name</label>
            <Input type="text" placeholder="Name" name="name" />
        </Col>
        <Col md="6">
            <label>Email</label>
            <Input type="text" placeholder="Email" name="mail" />
        </Col>
    </Row>
    <Row>
        <Col>
            <label>Message</label>
            <Input type="textarea" placeholder="Tell us your thoughts and feelings..." name="message" size="50" />
        </Col>
    </Row> <br />
    <div style={{paddingLeft: "46%"}}>
        <input type="submit" value="Send" />
    </div>
</form> */