import React from "react";
import "./Ourwork.scss";
import {Container, Row, Col} from "reactstrap";
import CountUp from 'react-countup';

class OurWorkPageContent extends React.Component {
	render () {
		return (
			<>
				<div className="development-main">
					<div className="work-heading">
						<span>Application Development & Support</span>
					</div>
					<Container>
						<Row>
							<Col className="ml-auto mr-auto" md="6">
								<div className="card">
									<img alt="" className="image" src={require("assets/img/compressedImages/filePetitions-min.jpg")}/>
									<div className="info">
										<h2>File Petitions</h2>
									</div>
								</div>
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="card">
									<img alt="" className="image" src={require("assets/img/compressedImages/resumeCrab-min.jpg")}/>
									<div className="info">
										<h2>Resume Crab</h2>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="pad-top">
							<Col className="ml-auto mr-auto" md="6">
								<div className="card">
									<img alt="" className="image" src={require("assets/img/compressedImages/electronicHealthCard-min.jpg")}/>
									<div className="info">
										<h2>Electronic Health Card</h2>
									</div>
								</div>
							</Col>
							<Col className="ml-auto mr-auto" md="6">
								<div className="card">
									<img alt="" className="image" src={require("assets/img/compressedImages/Immusservices-min.jpg")}/>
									<div className="info">
										<h2>Immusservices</h2>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<div className="staffing-heading container">
						<div className="staffing-work-heading">
							<span>Staffing & Recruitment</span>
						</div>
						<Row className="count-content-one">
							<Col className="ml-auto mr-auto" md="3">
								<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={974} duration={20}/>
								<h4>Posted Jobs</h4>
								<p>by Our Esteemed Organization</p>
							</Col>
							<Col className="ml-auto mr-auto" md="3">
								<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={6382} duration={15}/>
								<h4>Resumes Applied</h4>
								<p>For different profiles</p>
							</Col>
							<Col className="ml-auto mr-auto" md="3">
								<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={798} duration={20}/>
								<h4>Successfully Placed</h4>
								<p>In top organization</p>
							</Col>
							<Col className="ml-auto mr-auto" md="3">
								<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={912} duration={20}/>
								<h4>Candidates Referred</h4>
								<p>to their friends</p>
							</Col>
						</Row>
						<div className="count-content-two">
							<Row style={{paddingTop: "3%"}}>
								<Col className="ml-auto mr-auto" md="3">
									<CountUp style={{fontSize:"27px", fontWeight:"400"}} end={974} duration={20}/>
									<h4>Posted Jobs</h4>
									<p>by Our Esteemed Organization</p>
								</Col>
							</Row>
							<Row style={{paddingTop: "15%"}}>
								<Col className="ml-auto mr-auto" md="3">
									<CountUp style={{fontSize:"27px", fontWeight:"400"}} end={6382} duration={15}/>
									<h4>Resumes Applied</h4>
									<p>For different profiles</p>
								</Col>
							</Row>
							<Row style={{paddingTop: "15%"}}>
								<Col className="ml-auto mr-auto" md="3">
									<CountUp style={{fontSize:"27px", fontWeight:"400"}} end={798} duration={20}/>
									<h4>Successfully Placed</h4>
									<p>In top organization</p>
								</Col>
							</Row>
							<Row style={{paddingTop: "15%"}}>
								<Col className="ml-auto mr-auto" md="3">
									<CountUp style={{fontSize:"27px", fontWeight:"400"}} end={912} duration={20}/>
									<h4>Candidates Referred</h4>
									<p>to their friends</p>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default OurWorkPageContent;


/* <div className="main-flex">
	<div className="first-flex">
		<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={974} duration={20}/>
		<h4>Posted Jobs</h4> <br />
		<p>by Our Esteemed Organization</p>
	</div>
	<div className="second-flex">
		<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={6382} duration={15}/>
		<h4>Resumes Applied</h4> <br />
		<p>For different profiles</p>
	</div>
	<div className="third-flex">
		<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={798} duration={20}/>
		<h4>Successfully Placed</h4> <br />
		<p>In top organization</p>
	</div>
	<div className="fourth-flex">
		<CountUp style={{fontSize:"30px", fontWeight:"400"}} end={912} duration={20}/>
		<h4>Candidates Referred</h4> <br />
		<p>to their friends</p>
	</div>
</div> */

/* <a href="/application-development">
	<button>Read More</button>
</a>

<a href="/digital-transformation">
	<button>Read More</button>
</a>

<a href="/cloud-computing">
	<button>Read More</button>
</a>

<a href="/security-and-compliance">
	<button>Read More</button>
</a> */