import React from "react";
import ContactFormPresentational from "./ContactFormPresentational";
import axios from "axios";

class ContactFormContainer extends React.Component {

    state = {
        statusMsg : "", 
        statusDescriptionMsg : "",
    }

    contactApplicationForm = (state) => {

        const headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json', 
        }

        axios.post('https://reach-api.federalsoftsystems.com/rest/contact-details/add', {
            salutation : state.salutation || "",
            full_name : state.name || "",
            company_name : state.companyName || "",
            email : state.email || "",
            mobile_number : state.phoneNumber || "",
            purpose : state.purpose || "",
            message : state.message || "",
            country_name : state.country || "",
        }, {headers : headers})
        .then(response => {
            console.log('response in service', response.data.status);
            this.setState({statusMsg : response.data.status});
            this.setState({statusDescriptionMsg : response.data.status_description});
            return response;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
    }
    
    render() {
        return (
            <>
                <ContactFormPresentational contactApplicationForm={this.contactApplicationForm} statusMsg={this.state.statusMsg} statusDescriptionMsg={this.state.statusDescriptionMsg}/>
            </>
        )
    }
}

export default ContactFormContainer;



/* status: "contact_details-insertion-successful"
status_description: "Submitted successfully." */