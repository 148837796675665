import React from "react";

class BpoPageContent extends React.Component {
    render () {
        return (
            <>
                <p>BPO PAGE</p>
            </>
        )
    }
}

export default BpoPageContent;