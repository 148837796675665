import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";

// pages
/* import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js"; */

// others
import HomeMainContent from "components/HomePage";
import WhoWeAreMainContent from "components/WhoWeAre";
import WhatWeDoMainContent from "components/WhatWeDo";
import OurWorkMainContent from "components/OurWork";
import OurPresenceMainContent from "components/OurPresence";
import CareersMainContent from "components/Careers";
import ContactMainContent from "components/Contact";
import DevelopmentMainContent from "components/Development";
import StaffingMainContent from "components/Staffing";
import BpoMainContent from "components/Bpo";
import PayrollMainContent from "components/Payroll";
import ApplicationDevelopment from "components/Development/DevContent/applicationDevelopment";
import DigitalTransformation from "components/Development/DevContent/digitalTransformation";
import CloudComputing from "components/Development/DevContent/cloudComputing";
import Security from "components/Development/DevContent/security";
import JobViewContainer from "components/Careers/JobView";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={props => <HomeMainContent {...props} />} />
      <Route path="/who-we-are" render={props => <WhoWeAreMainContent {...props} />} />
      <Route path="/what-we-do" render={props => <WhatWeDoMainContent {...props} />} />
      <Route path="/our-work" render={props => <OurWorkMainContent {...props} />} />
      <Route path="/our-presence" render={props => <OurPresenceMainContent {...props} />} />
      <Route path="/careers" render={props => <CareersMainContent {...props} />} />
      <Route path="/contact" render={props => <ContactMainContent {...props} />} />    

      <Route path="/development" render={props => <DevelopmentMainContent {...props} />} />    
      <Route path="/staffing-and-recruiting" render={props => <StaffingMainContent {...props} />} />    
      <Route path="/bpo" render={props => <BpoMainContent {...props} />} />    
      <Route path="/payroll" render={props => <PayrollMainContent {...props} />} />    

      <Route path="/application-development" render={props => <ApplicationDevelopment {...props} />} />   
      <Route path="/digital-transformation" render={props => <DigitalTransformation {...props} />} />   
      <Route path="/cloud-computing" render={props => <CloudComputing {...props} />} />   
      <Route path="/security-and-compliance" render={props => <Security {...props} />} />

      <Route path="/job-application/:job_id" render={props => <JobViewContainer {...props} />} />   

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);



/* <Route path="/index" render={props => <Index {...props} />} />
  <Route
    path="/nucleo-icons"
    render={props => <NucleoIcons {...props} />}
  />
  <Route
    path="/landing-page"
    render={props => <LandingPage {...props} />}
  />
  <Route
    path="/profile-page"
    render={props => <ProfilePage {...props} />}
  />
  <Route
    path="/register-page"
    render={props => <RegisterPage {...props} />}
  /> */