import React from "react";
import "../Contact.scss";
import SweetAlert from "react-bootstrap-sweetalert";

const initialState = {
    salutation: "",
    name: "",
    nameError: "",
    companyName: "",
    email: "",
    emailError: "",
    phoneNumber : "",
    phoneNumberError : "",
    country : "",
    countryError : "",
    purpose : "",
    purposeError : "",
    message : "",
    sweet : false,
}

class ContactFormPresentational extends React.Component {

    state = initialState;

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
        [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
    };

    validate = () => {
        let nameError = "";
        let emailError = "";
        let phoneNumberError = "";
        let countryError = "";
        let purposeError = "";

        if (!this.state.name) {
            nameError = "This field is required";
        }

        if (!this.state.email.includes("@")) {
            emailError = "Invalid email";
        }

        if (!this.state.phoneNumber) {
            phoneNumberError = "This field is required";
        }

        if (!this.state.country) {
            countryError = "This field is required";
        }

        if (!this.state.purpose) {
            purposeError = "This field is required";
        }

        if (nameError || emailError || phoneNumberError || countryError || purposeError) {
            this.setState({nameError, emailError, phoneNumberError, countryError, purposeError});
            return false;
        }

        return true;
    };

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            console.log(this.state);
            this.props.contactApplicationForm({...this.state})
            // clear form
            this.setState(initialState);
            this.setState({sweet : true});
        }
    };

    render() {
        const {statusMsg, statusDescriptionMsg} = this.props;
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <select
                            name="salutation"
                            value={this.state.salutation}
                            onChange={this.handleChange}
                        >
                            <option value="">Salutation</option>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Dr">Dr</option>
                        </select>
                    </div> <br />
                    <div>
                        <input
                            name="name"
                            placeholder="Full Name (Required)"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        <div className="validationMsg">
                            {this.state.nameError}
                        </div>
                    </div> <br />
                    <div>
                        <input
                            name="companyName"
                            placeholder="Company Name"
                            value={this.state.companyName}
                            onChange={this.handleChange}
                        />
                    </div> <br />
                    <div>
                        <input
                            name="email"
                            placeholder="Email (Required)"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        <div className="validationMsg">
                            {this.state.emailError}
                        </div>
                    </div> <br />
                    <div>
                        <input
                            name="phoneNumber"
                            placeholder="Mobile Number (Required)"
                            value={this.state.phoneNumber}
                            onChange={this.handleChange}
                        />
                        <div className="validationMsg">
                            {this.state.phoneNumberError}
                        </div>
                    </div> <br />
                    <div>
                        <select
                            name="country"
                            value={this.state.country}
                            onChange={this.handleChange}
                        >
                            <option value="">Country (Required)</option>
                            <option value="Usa">United States</option>
                            <option value="India">India</option>
                        </select>
                        <div className="validationMsg">
                            {this.state.countryError}
                        </div>
                    </div> <br />
                    <div>
                        <select
                            name="purpose"
                            value={this.state.purpose}
                            onChange={this.handleChange}
                        >
                            <option value="">Purpose (Required)</option>
                            <option value="Job Seeker">Job Seeker</option>
                            <option value="Product Development">Product Development</option>
                            <option value="Staffing Organization">Staffing Organization</option>
                        </select>
                        <div className="validationMsg">
                            {this.state.purposeError}
                        </div>
                    </div> <br />
                    <div>
                        <textarea
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            placeholder="How can we assist you ?"
                        />
                    </div> <br />
                    
                    <button type="submit">submit</button>
                </form>
                <div>
                    {statusMsg && this.state.sweet && 
                        <SweetAlert
                            title=""
                            btnSize="sm"
                            onConfirm={() => this.setState({sweet : false})}
                        >
                            <h4>{statusDescriptionMsg}</h4>
                        </SweetAlert>
                    }
                </div>
            </>
        )
    }
}

export default ContactFormPresentational;