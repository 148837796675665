import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import DevelopmentPageContent from "./DevelopmentPageContent";

class DevelopmentMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                <div 
                    className="page-header section-dark"
                    style={{
                        backgroundImage:
                        "url(" + require("assets/img/customImages/development.jpg") + ")"
                    }}
                >
                </div>
                <DevelopmentPageContent />
                <FooterTwo />
            </>
        )
    }
}

export default DevelopmentMainContent;