import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import WhatWeDoPageContent from "./WhatWeDoPageContent";
import FooterTwo from "components/CustomFooterTwo";

class WhatWeDoMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                <div className="main-what-we-do-page-video">
                    <div className="page-header section-dark video-bg" style={{height: "100vh"}}>
                        <video poster={require("./whatWeDo.png")} autoPlay loop muted>
                            <source src={require("assets/img/videos/whatWeDo.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className="ipad-what-we-do-page-video">
                    <div className="page-header section-dark" style={{height: "100vh"}}>
                        <video poster={require("./whatPage.jpg")} autoPlay loop muted>
                            <source src={require("assets/img/videos/ipad-whatWeDo.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className="phone-what-we-do-page-video">
                    <div className="page-header section-dark" style={{height: "100vh"}}>
                        <video poster={require("./whatPage.jpg")} autoPlay loop muted>
                            <source src={require("assets/img/videos/phone-whatWeDo.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <WhatWeDoPageContent />
                <FooterTwo /> 
            </>
        )
    }
}

export default WhatWeDoMainContent; 






