import React from "react";
import {Row, Col, Container} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import "../careers.scss";
import { Link } from "react-router-dom";

const initialState = {
  name: "",
  nameError: "",
  lastName: "",
  lastNameError: "",
  email: "",
  emailError: "",
  phoneNumber : "",
  phoneNumberError : "",
  resume : "",
  resumeError : "",
  visaStatus : "",
  visaStatusError : "",
  visaSponsorship : "",
  visaSponsorshipError : "",
  fileContent : "",
  orginalFileName : "",
  sweet : false,
};

class JobApplicationPresentational extends React.Component {

    state = initialState;

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
        [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
    };

    handleChangeTwo = event => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
        [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.setState({orginalFileName : event.target.files[0].name})
        reader.onload = () => {
            const base64Code = reader.result;
            console.log('base64code', base64Code);
            this.setState({fileContent : base64Code});
        }
    }

    validate = () => {
        let nameError = "";
        let lastNameError = "";
        let emailError = "";
        let phoneNumberError = "";
        let resumeError = "";
        let visaStatusError = "";
        let visaSponsorshipError = "";

        if (!this.state.name) {
            nameError = "This field is required";
        }

        if (!this.state.lastName) {
            lastNameError = "This field is required";
        }

        if (!this.state.email.includes("@")) {
            emailError = "Invalid email";
        }

        if (!this.state.phoneNumber) {
            phoneNumberError = "This field is required";
        }

        if (!this.state.resume) {
            resumeError = "This field is required";
        }

        if (!this.state.visaStatus) {
            visaStatusError = "This field is required";
        }

        if (!this.state.visaSponsorship) {
            visaSponsorshipError = "This field is required";
        }

        if (nameError || lastNameError || emailError || phoneNumberError || resumeError || visaStatusError || visaSponsorshipError) {
            this.setState({nameError, lastNameError, emailError, phoneNumberError, resumeError, visaStatusError, visaSponsorshipError });
            return false;
        }

        return true;
    };

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            console.log(this.state);
            this.props.jobApply({...this.state});
            // clear form
            this.setState(initialState);
            this.setState({sweet : true});
        }
    };

    render() {
        const {statusMsg, statusDescriptionMsg} = this.props;
        return (
            <>
            <Container>
                <div className="hr-line"></div>
                <div className="job-apply-heading">Apply to job</div>
                <form onSubmit={this.handleSubmit}>
                    <Row className="form-main">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> First Name 
                                </label>
                                <input
                                    name="name"
                                    placeholder="Enter First Name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                                <div className="validationMsg">
                                    {this.state.nameError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> Last Name</label>
                                <input
                                    name="lastName"
                                    placeholder="Enter Last Name"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                />
                                <div className="validationMsg">
                                    {this.state.lastNameError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> Email</label>
                                <input
                                    name="email"
                                    placeholder="Enter Email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                                <div className="validationMsg">
                                    {this.state.emailError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> Phone Number</label>
                                <input
                                    name="phoneNumber"
                                    placeholder="Enter Phone Number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChange}
                                />
                                <div className="validationMsg">
                                    {this.state.phoneNumberError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> Resume</label>
                                <input type="file" name="resume" value={this.state.resume}
                                onChange={this.handleChangeTwo}/>
                                <div className="validationMsg">
                                    {this.state.resumeError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> What's your visa status ?</label>
                                <input
                                    name="visaStatus"
                                    placeholder="Enter visa status"
                                    value={this.state.visaStatus}
                                    onChange={this.handleChange}
                                />
                                <div className="validationMsg">
                                    {this.state.visaStatusError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div style={{marginTop : "6%"}}>
                                <label className="labelStyle">
                                    <span style={{color : "red", fontSize : "17px"}}>*</span> Will you need visa sponsorship ?</label>
                                <select
                                    name="visaSponsorship"
                                    value={this.state.visaSponsorship}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <div className="validationMsg">
                                    {this.state.visaSponsorshipError}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="submitButton">
                                <button type="submit">submit</button>
                            </div> 
                        </Col>
                    </Row> <br />                   
                </form>
                <div className="hr-line"></div>
            </Container>
            <div>
                {statusMsg && this.state.sweet && 
                    <SweetAlert
                        title=""
                        btnSize="sm"
                        onConfirm={() => this.setState({sweet : false})}
                        confirmBtnText={statusMsg === "job-applied" ? <Link to={"/"}><span style={{color : "white"}}>Ok</span></Link> : "Ok"}
                        confirmBtnBsStyle="primary"
                    >
                        <h4>{statusDescriptionMsg}</h4>
                    </SweetAlert>
                }
            </div>
        </>
        );
    }
}

export default JobApplicationPresentational;


