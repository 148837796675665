import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import OurPresencePageContent from "./OurPresencePageContent";

class OurPresenceMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                    <div 
                        className="page-header section-dark"
                        style={{
                            backgroundImage:
                            "url(" + require("assets/img/customImages/ourPresence.jpg") + ")"
                        }}
                    >
                    </div>
                <OurPresencePageContent />
                <FooterTwo />
            </>
        )
    }
}

export default OurPresenceMainContent;