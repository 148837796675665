import React from "react";
import {Row, Col} from "reactstrap";
import "./Ourpresence.scss";

class OurPresencePageContent extends React.Component {
    render () {
        return (
            <>
                <div className="our-main-presence">
                    <span className="our-title">OUR</span> 
                    <span className="presence-title"> PRESENCE</span>
                    
                    <Row className="cards">
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="first-card">
                                <div className="card-content">Banking & Financial Services</div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="second-card">
                                <div className="card-content">Insurance, Health Care & Life Sciences</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cards">
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="third-card">
                                <div className="card-content">Retail & Consumer Products</div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="fourth-card">
                                <div className="card-content">Media & Entertainment</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cards">
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="fifth-card">
                                <div className="card-content">Telecom & Internet Services</div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="sixth-card">
                                <div className="card-content">Manufacturing & Logistics</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cards">
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="seventh-card">
                                <div className="card-content">Product & Engineering Services</div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                            <div className="eight-card">
                                <div className="card-content">Hi-Tech & Ed-Tech</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default OurPresencePageContent;


/* <Col className="ml-auto mr-auto" md="6">
<div className="ninth-card">
    <div className="card-content">Travel, Transportation & Hospitality</div>
</div>
</Col> */