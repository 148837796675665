import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";

class CloudComputing extends React.Component {
	render() {
		return (
			<>
				<IndexNavbar />
				<div 
					className="page-header section-dark"
					style={{
						backgroundImage:
						"url(" + require("assets/img/customImages/cloudComputing-min.jpg") + ")"
					}}
				>
				</div>
				<div className="all-pages-content">
					<p>
						Cloud computing is a type of computing that relies on pooled computing resources but not on local servers or personal devices for application management. Cloud computing offers Internet-based ("cloud") technology that makes for rapid growth, flexible resources and economies of scale, including servers, storage, information bases, networking, software, analysis, and intellectual property. Typically, you just pay for the cloud resources you use, allowing you to minimize expenses, run the network effectively and scale up with improvements in your business needs.
					</p>
				</div>
				<FooterTwo />
			</>
		)
	}
}

export default CloudComputing;