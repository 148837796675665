import React from "react";
import {Container} from "reactstrap";
import JobApplicationContainer from "../JobApplicationForm";
import "../careers.scss";

class JobViewPresentational extends React.Component {
    render() {
        const {ApplyJobsList} = this.props;
        console.log('ApplyJobsList in presentational', ApplyJobsList);

        let replacementData = ApplyJobsList && ApplyJobsList.job_full_description;
        const regex = /&rsquo;/gi
        const regexTwo = /&nbsp;/gi

        const modifiedData = replacementData.replace(regex, `'`).replace(regexTwo, '');
        console.log('modified data', modifiedData);

        return (
            <>
                <div className="job-main-content">
                    <Container>
                        <img className="image-align" height="50" alt="" src={require("assets/img/customImages/FSS.png")}/>
                        <div className="hr-line"></div>
                        <div className="job-position-main">
                            <div className="position-title">
                                {ApplyJobsList && ApplyJobsList.job_title}
                            </div>
                            <span className="city">Job Code : {ApplyJobsList && ApplyJobsList.job_code}</span>
                            <div className="responsibilites">
                                <div className="responsibility-heading">Employer</div>
                                <div className="responsibility-content">
                                    Federal Soft Systems, Inc.
                                </div>
                            </div>
                            <div className="responsibilites">
                                <div className="responsibility-heading">Duties</div>
                                <div className="responsibility-content">
                                    {modifiedData}
                                </div>
                            </div>
                            
                            <div className="responsibilites">
                                <div className="responsibility-content">
                                    <b>Location : </b>
                                    <span className="city">{ApplyJobsList && ApplyJobsList.city} {ApplyJobsList && ApplyJobsList.state}</span>        
                                    <span className="country"> {ApplyJobsList && ApplyJobsList.country}</span>
                                </div>
                                <div className="responsibility-content">
                                    <br/>
                                    <b>Salary : </b>
                                    <span className="city">${ApplyJobsList && ApplyJobsList.bill_rate_value} per {ApplyJobsList && ApplyJobsList.bill_rate_job_compensation_period_seo_name}</span>
                                </div>
                            </div>                            
                            <div className="responsibilites">
                                <div className="responsibility-heading">Minimum Qualifications</div> <br/>                               
                                <div className="responsibility-content">
                                    <b>Education : </b>{ApplyJobsList && ApplyJobsList.minimum_qualifications}
                                </div><br/>
                                <div className="responsibility-content">
                                    <b>Training : </b>None
                                </div><br/>
                                <div className="responsibility-content">
                                    <b>Experience : </b>{ApplyJobsList && ApplyJobsList.experience_info}
                                </div><br/>
                                <div className="responsibility-content">
                                    <b>Other Requirements : </b>{ApplyJobsList && ApplyJobsList.job_skills}
                                </div><br/>
                                <div className="responsibility-content">
                                    {ApplyJobsList && ApplyJobsList.job_summary}
                                </div>
                            </div>
                            <div className="responsibilites">
                                <div className="responsibility-heading">Employer Contact</div>
                                <div className="responsibility-content">
                                    {ApplyJobsList && ApplyJobsList.employer_contact_info}
                                </div>
                            </div>
                        </div>
                    </Container><br/>
                    <JobApplicationContainer ApplyJobsList={ApplyJobsList}/>
                </div>
            </>
        )
    }
}

export default JobViewPresentational;



/* <div className="job-main-content">
    <Container>
        <img className="image-align" height="50" alt="" src={require("assets/img/customImages/FSS.png")}/>
        <div className="hr-line"></div>
        <div className="job-position-main">
            <div className="position-title">{ApplyJobsList && ApplyJobsList.job_title}</div>
            <span className="city">{ApplyJobsList && ApplyJobsList.city} - </span>
            <span className="city"> {ApplyJobsList && ApplyJobsList.state}</span>
            <span className="country"> - {ApplyJobsList && ApplyJobsList.country}</span>
            <div className="responsibilites">
                <div className="responsibility-heading">responsibilites</div>
                <div className="responsibility-content">
                    {ApplyJobsList && ApplyJobsList.job_full_description}
                </div>
            </div>
            <div className="responsibilites">
                <div className="responsibility-heading">minimum qualifications</div>
                <div className="responsibility-content">
                    {ApplyJobsList && ApplyJobsList.minimum_qualifications}
                </div>
            </div>
            <div className="responsibilites">
                <div className="responsibility-heading">skills</div>
                <div className="responsibility-content">
                    {ApplyJobsList && ApplyJobsList.job_skills}
                </div>
            </div>
            <div className="experiance">
                <div className="experiance-heading">experience</div>
                <div className="experiance-content">
                    <div>Min Experience : {ApplyJobsList && ApplyJobsList.min_experience_period}</div> <br />
                    <div>Max Experience : {ApplyJobsList && ApplyJobsList.max_experience_period}</div>
                </div>
            </div>
            <div className="environment">
                <div className="environment-heading">environment</div>
                <div className="environment-content">
                    <p>Professional, competitive, and enjoyable</p>
                    <p>Your work has high visibility and is appreciated by millions</p>
                    <p> Work with bright, talented colleagues</p>
                    <p>Friday Events to present all your friends your jobs, catered lunches, gaming nights, ping-pong tournaments and much more.</p>
                    <p>The best aspect, We Work Quickly-within 7 days of first technical interview we will make an offer</p>
                </div>
            </div>
            <div className="about-us">
                <div className="about-us-heading">about us</div>
                <div className="about-us-content">
                    We are honored to become a valued member of our clients. We help them manage the environment and become trusted partners in their decisions regarding their development strategies. And, no other company will compare with our ability to turn consumer approaches into real returns on investment. We are the leaders in business.
                </div>
            </div>
        </div>
    </Container> <br />
    <JobApplicationContainer ApplyJobsList={ApplyJobsList}/>
</div> */