import React from "react";
import "./FooterTwo.scss";

class FooterTwo extends React.Component {
    render () {
        const year = new Date();
        const currentYear = year.getFullYear();
        
        return (
            <>
                <div className="main-address">
                    <div className="reach-heading">YOU MAY ALSO REACH US AT</div>
                    <div className="address-flex">
                        <div className="usa-address">
                            <span>+1 (201) 716-9222</span> <br />
                            <span>contact@federalsoftsystems.com</span>
                        </div>
                        <div className="india-address">
                            <span>040-43464266</span> <br />
                            <span>contactindia@federalsoftsystems.com</span>
                        </div>
                    </div>
                </div>
                <div className="footer-main">
                                       
                    <footer className="flex-rw">
                    
                        <ul className="footer-list-top">
                            <li><h4 className="footer-list-header">About FSS</h4></li>
                            <li><a href='/home' className="generic-anchor footer-list-anchor">HOME</a></li>
                            <li><a href='/who-we-are' className="generic-anchor footer-list-anchor">WHO WE ARE</a></li>
                            <li><a href='/what-we-do' className="generic-anchor footer-list-anchor">WHAT WE DO</a></li>
                            <li><a href='/our-work' className="generic-anchor footer-list-anchor">OUR WORK</a></li>
                            <li><a href='our-presence' className="generic-anchor footer-list-anchor">OUR PRESENCE</a></li>
                        </ul>
                        
                        <ul className="footer-list-top">
                            <li><h4 className="footer-list-header">Our Services</h4></li>
                            <li><a href='/development' className="generic-anchor footer-list-anchor">APPLICATION DEVELOPMENT</a></li>
                            <li><a href='/staffing-and-recruiting' className="generic-anchor footer-list-anchor">STAFFING & RECRUITMENT</a></li>
                            <li className="generic-anchor footer-list-anchor">BPO</li>
                            <li className="generic-anchor footer-list-anchor">PAYROLL</li>
                        </ul>

                        <ul className="footer-list-top">
                            <li id='help'>
                            <h4 className="footer-list-header">Capabilities</h4></li>
                            <li><a href='/application-development' className="generic-anchor footer-list-anchor">APPLICATION DEVELOPMENT</a></li>
                            <li><a href='/digital-transformation' className="generic-anchor footer-list-anchor">DIGITAL TRANSFORMATION</a></li>
                            <li id='find-a-store'><a href='/security-and-compliance' className="generic-anchor footer-list-anchor">SECURITY & COMPLIANCE</a></li>
                            <li id='user-registration'><a href='/cloud-computing' className="generic-anchor footer-list-anchor">CLOUD COMPUTING</a></li>
                        </ul>

                        <ul className="footer-list-top">
                            <li id='help'>
                            <h4 className="footer-list-header">Get In Touch</h4></li>
                            <li><a href='/contact' className="generic-anchor footer-list-anchor">CONTACT</a></li>
                        </ul>

                        <section className="footer-social-section flex-rw">
                            <span className="footer-social-overlap footer-social-connect">
                                CONNECT <span className="footer-social-small">with</span> US
                            </span>
                            <span className="footer-social-overlap footer-social-icons-wrapper">
                                
                                <a href="https://www.facebook.com/federalsoftsystems/" className="generic-anchor" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fa fa-facebook"></i></a>
                                <a href="https://www.linkedin.com/company/federal-soft-systems-inc" className="generic-anchor" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="fa fa-linkedin"></i></a>
                                
                                
                            </span>
                        </section>

                        <section className="footer-bottom-section flex-rw">
                            <div className="footer-bottom-wrapper">   
                                <i className="fa fa-copyright" role="copyright">                  
                                </i> {currentYear} Federal Soft Systems <span className="footer-bottom-rights"> - All Rights Reserved</span>
                            </div>
                            
                        </section>
                    </footer>
                </div>
            </>
        )
    }
}

export default FooterTwo;


/* <a href="#" className="generic-anchor" target="_blank" title="Twitter"><i className="fa fa-twitter"></i></a> */




