import React from "react";
import {Container, Row, Col} from "reactstrap";
import "./staffing.scss";

class StaffingPageContent extends React.Component {
    render () {
        return (
            <>
                <div className="staffing-main">
                    <Container>
                        <p>
                            FSS enables you to get highly skilled IT personnel just when and where you need them across an amazingly wide range of technologies and domains to help you form and strengthen your team in order to remain flourishing and competitive in the marketplace. The FSS offering lends you unique advantages and value propositions whether you need to extend your IT capabilities or acquire specialized IT skills to develop, manage, enhance and support your mission critical applications.
                        </p>
                        <p>
                            Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies. Our clients can ramp up a team on/offshore very quickly; taking advantage of significant cost savings without any of the hassles. FSS offerings are governed by its sharp focus on “understanding the customer’s needs and priorities in minutest details” and providing “the right IT resources with the stipulated timelines”
                        </p>
                        <div className="main-staffing-models-content">
                            <div className="staffing-heading"> 
                                <span className="our">OUR</span> 
                                <span className="staffing-models"> STAFFING MODELS</span>
                            </div>
                            <Row className="common-padding">
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/managedService-min.jpg")}/>
                                        <div className="intial-text">Managed Services Model</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                we listen to your needs to better understand your recruiting challenges, culture & goals
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/recruitmentProcess-min.jpg")}/>
                                        <div className="intial-text">Recruitment Process Outsourcing</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                we draw upon our experiance to offer custom solution, specific to you & your organization
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="common-padding">
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/cantract-min.jpg")}/>
                                        <div className="intial-text">Contract Resource Fulfillment</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                we collabrate with you yo put our agreed upon strategy into action
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/fullTime-min.jpg")}/>
                                        <div className="intial-text">Fulltime Resource Fulfillment</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                we constantly measure our progress to ensure you are on track to achieve your talent needs
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="common-padding">
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/leadership-min.jpg")}/>
                                        <div className="intial-text">Leadership / Strategic Resource Fulfillment</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                we use both cutting-edge & proven techniques to deliver the talent you need
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto column-padding" md="6">
                                    <div className="main-container">
                                        <img alt="" className="image" src={require("assets/img/compressedImages/staffing2.jpg")}/>
                                        <div className="intial-text">FSS Staffing Advantage</div>
                                        <div className="overlay">
                                            <div className="hover-text">
                                                FSS Staffing Advantage 
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default StaffingPageContent;




/*<Row>
    <Col className="ml-auto mr-auto" md="6">
        <div className="staffing-image"></div>
    </Col>
    <Col className="ml-auto mr-auto" md="6">
        <p>
            FSS enables you to get highly skilled IT personnel just when and where you need them across an amazingly wide range of technologies and domains to help you form and strengthen your team in order to remain flourishing and competitive in the marketplace. The FSS offering lends you unique advantages and value propositions whether you need to extend your IT capabilities or acquire specialized IT skills to develop, manage, enhance and support your mission critical applications.
        </p>
        <p>
            Federal Soft Systems has a recruitment team who can build a team & provide a resource according to exact specification, while employing our proven HR practices and program management methodologies. Our clients can ramp up a team on/offshore very quickly; taking advantage of significant cost savings without any of the hassles. FSS offerings are governed by its sharp focus on “understanding the customer’s needs and priorities in minutest details” and providing “the right IT resources with the stipulated timelines”
        </p>
    </Col>
</Row> */