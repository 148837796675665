import React from "react";
import CareersPresentational from "./CareersPresentational";
import axios from "axios";

class CareersContainer extends React.Component {

    state = {
        JobsList : "",
        listLength : "",
    }

    componentDidMount = (country) => {

        const headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json', 
        }

        axios.post('https://api-live.resumecrab.com/rest/pre-login/job-list', {
            page_number : "",
            company_id : "1",
            number_of_records : "",
            sort_field : "",
            country : country || "united states",
            api_key : "3234c71c-5fad-4857-84bf-6d32def3d650",
            time_period : "",
            sort_order : "",
            search_criteria : "",
            company_client_id : "",
            job_recruitment_status : "",
            resume_acceptance_status : "",
            ip_address : "49.206.214.42",
        }, {headers : headers})
        .then(response => {
            console.log('response in service', response);
            this.setState({JobsList : response.data.data.list});
            this.setState({listLength : response.data.data.total_records_count})
            return response;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
    }
    
    render() {

        return (
            <>
                <CareersPresentational componentDidMount = {this.componentDidMount} JobsList = {this.state.JobsList} listLength={this.state.listLength}/>
            </>
        )
    }
}

export default CareersContainer;