import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterTwo from "components/CustomFooterTwo";
import HomePageContent from "./HomePageContent";
import "./Home.scss";

class HomeMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                <div className="main-home-page-video">
                    <div className="page-header section-dark" style={{height: "100vh"}}>
                        <video poster={require("./homePagePic.png")} autoPlay loop muted>
                            <source src={require("assets/img/videos/homePageWeb.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className="ipad-home-page-video">
                    <div className="page-header section-dark" style={{height: "100vh"}}>
                        <video poster={require("./homePagePic.png")} autoPlay loop muted>
                            <source src={require("assets/img/videos/homePageIpad.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className="phone-home-page-video">
                    <div className="page-header section-dark" style={{height: "100vh"}}>
                        <video poster={require("./homePagePic.png")} autoPlay loop muted>
                            <source src={require("assets/img/videos/homePagePhone.mp4")} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <HomePageContent />
                <FooterTwo />
            </>
        )
    }
}

export default HomeMainContent;