import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import WhoWeArePageContent from "./WhoWeArePageContent";
import FooterTwo from "components/CustomFooterTwo";

class WhoWeAreMainContent extends React.Component {
    render () {
        return (
            <>
                <IndexNavbar />
                <div 
                    className="page-header section-dark"
                    style={{
                        backgroundImage:
                        "url(" + require("assets/img/compressedImages/whoWeAre3.jpg") + ")"
                    }}
                >
                </div>
                <WhoWeArePageContent />
                <FooterTwo />
            </>
        )
    }
}

export default WhoWeAreMainContent; 